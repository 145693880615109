import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import withParticles from './withParticles';
import { Link } from 'react-router-dom';

function Main() {
  return (
    <header className="App-header">
      <div className="profile-photo"></div>

      <div className="main_text">
        <h2>Marc Johnson</h2>
      </div>
      <div className="main_text">
        <p>
          <a href="https://novaenergy.ai/" className="App-link" target="_blank" rel="noopener noreferrer">
            <b>Nova Energy</b>
          </a>
        </p>
      </div>
      <div className="main_text">
            <Link to="/About">
              <button className="button">About</button>
            </Link>
      </div>
    </header>
  );
}

export default withParticles(Main);
